import React from 'react'
import { PageProps } from "gatsby"
import Layout from '../components/Layout/Layout'
import SEO from '../components/seo'

import Search from '../components/Search/SearchPage'

const busca: React.FC<PageProps> = ({ location }) => {
   return (
      <Layout location={location}>
      <SEO title={`Resultados de busca para "${location?.state?.searchInput}"` || 'Busca'} />
         <Search location={location} />
      </Layout>
   )
}

export default busca
