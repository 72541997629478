import React, { useState, useEffect } from 'react'
import styles from './Search.module.scss'
import ProductCard from '../Category/components/productCard'
import ClassNames from 'classnames/bind'

let cx = ClassNames.bind(styles);

type SearchPageType = {
   location: any
}

const SearchPage: React.FC<SearchPageType> = ({ location }) => {
   const [products, setProducts] = useState([]);

   useEffect(() => {
      if (location !== undefined && location !== null && location.state !== null && location.state !== undefined && 'products' in location.state) {
         setProducts(location.state.products)
      }
   }, [location.state])
   console.log(location)
   let idx = 0;

   return (
      <div>
         <div style={{ backgroundColor: '#fff', padding: '10px 15px', borderBottom: '3px solid #f8f7f6' }}>
            Resultados de busca para "<strong>{location?.state?.searchInput}</strong>"
      </div>
         <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {products.map((product, index) => {
               idx = idx + 1;
               const ClassName = cx({
                  cardMosaico: idx % 5 !== 0,
                  lastCardMosaico: idx % 5 === 0,
               });
               return (
                  <div key={index} className={ClassName}>
                     <ProductCard product={product} displayMode={'mosaico'} location={location} />
                  </div>
               )
            })}
         </div>
      </div>
   )
}

export default SearchPage
